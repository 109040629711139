.login{
    display: grid;
    align-items: center;
    height: 100vh;
    background-image: url(../../img/blog/img-blog.jpg);
    background-size: cover;
    background-position: 50%;

    .loginNav{
        position: fixed;
        top: 0px;
        height: fit-content;
        padding: 20px 20px 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        backdrop-filter: blur(5px);
        border-bottom: 1px solid rgba(128, 128, 128, 0.123);
        z-index: 1000;
        max-height: 200px;
        
        .back{
            background-color: var(--cor-tertiary);
            height: fit-content;
            align-self: center;
            color: white;
            padding: 10px;
            border-radius: 10px;
            transition: .5s ease;
        }
        .back:hover{
            background-color: var(--cor-primary);
        }

        .logo{
            width: 180px;
        }

        .medias{
            display: flex;
            gap: 10px;
            align-items: center;
            
            a{
                height: fit-content;
                transition: .5s ease;

                img{
                    width: 40px;
                    background-image: linear-gradient(45deg, #00975671, #0f66407c);
                    padding: 8px;
                    border-radius: 10px;
                }
            }
            a:hover{
                transform: translateY(5px);
            }
        }
    }

    .loginContent{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        /* backdrop-filter: blur(5px); */
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.288) 102px, rgba(0, 0, 0, 0.377) 102px);
        height: 100%;

        form{
            grid-column-end: 2;
            grid-column-end: 3;
            display: grid;
            align-items: center;
            align-self: center;
            justify-self: center;
            text-align: center;
            max-width: 500px;
            color: white;
            font-weight: 200;

            label{
                text-align: left;
                font-size: 15px;
            }
            input{
                background-color: rgba(255, 255, 255, 0.041);
                backdrop-filter: blur(10px);
                color: white;
                box-shadow: none;
                border: 1px solid gray;
                transition: .5s ease;
            }
            input:focus{
                background-color: rgba(255, 255, 255, 0.103);
            }

            button {
                background-color: rgba(255, 255, 255, 0);
                position: relative;
                padding: 20px 50px;
                justify-self: center;
                display: block;
                text-decoration: none;
                text-transform: uppercase;
                width: 200px;
                overflow: hidden;
                border-radius: 40px;
                align-self: center;
                justify-self: center;
    
                span {
                    position: relative;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: normal;
                    z-index: 100;
                }
    
                .liquid {
                    position: absolute;
                    top: -130px;
                    left: 0;
                    width: 300px;
                    height: 300px;
                    background: var(--cor-primary);
                    box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
                    transition: .5s;
                }
    
                .liquid::after,
                .liquid::before {
                content: '';
                    width: 200%;
                    height: 200%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -75%);
                    background: #004629;
                }
                .liquid::before {
                    border-radius: 45%;
                    background: #004629;
                    animation: animate 5s linear infinite;
                }
                .liquid::after {
                    border-radius: 40%;
                    background: rgba(20, 20, 20, .5);
                    animation: animate 10s linear infinite;
                }
            }
    
            button:hover{
                .liquid{
                    top: -180px;
                }
            }
        }
    }
    .loginFooter{
        position: fixed;
        bottom: 0px;
        width: 100%;

        p{
            text-align: center;
            color: white;
            font-size: 14px;
            font-weight: 300;

            a{
                color: white;
            }
        }
    }
}

@media (max-width:1000px){
    .login{
        .loginContent{
            grid-template-columns: 100%;

            form{
                grid-column-start: 1;
                grid-column-end: 2;
            }
        }
    }
}

@media (max-width:600px){
    .login{
        .loginNav{
            justify-content: center;
            .back, .medias{
                display: none;
            }
        }
        .loginContent{
            padding: 0px 20px;

            form{
                p{
                    font-size: 14px;
                }
            }
        }
    }
}


@keyframes waves {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}

@keyframes animate {
    0% {
        transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -75%) rotate(360deg);
    }
}